:root {
  --y: 50%;
  --blur: 0.5vmax;
  --focus-range: 1vh;
  --blur-range: 25vh;
}

.ContactUs-content {
  color: #ffffff;
}

.wrapperContactUs {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  overflow: hidden;
}
.ContactUs-background {
  background-image: url(../../assets/images/background_img/88c7a038d5.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.news-background {
  background-image: url(../../assets/images/background_img/newsss.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.Vacancies-wrapper {
  background-image: url(../../assets/images/background_img/company-manager-interviews-new-employee\ 1.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.news-Careers {
  background-image: url(../../assets/images/background_img/job_app.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 520px) {
  .news-Careers {
    background-position: bottom;
    background-size: cover;
  }
}

@media only screen and (max-width: 992px) {
  .news-Careers {
    background-position: bottom;
    background-size: cover;
  }
}
@media only screen and (max-width: 768px) {
  .news-Careers {
    background-position: bottom;
    background-size: cover;
  }
}

.background {
  display: flex;
  min-height: 65vh;
}

.container {
  flex: 0 1 740px;
  margin: auto;
  padding: 10px;
}

.contact {
  position: relative;
  background: #3e3e3e;
  border-radius: 15px;
}

.contact:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.contact-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #4d4d4f;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.contact-header-left {
  margin-right: auto;
}

.contact-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: #999;
}

.contact-body {
  display: flex;
}

.contact-body-item {
  flex: 1;
  padding: 50px;
}

.contact-body-item.left {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 576px) {
  .contact-body {
    display: flex;
    flex-wrap: wrap;
  }
  .contact-body-item {
    flex: auto;
    padding: 40px;
  }
  .app-contact-cursor {
    margin-top: 3rem !important;
  }
  .app-contact {
    margin-top: 1rem !important;
  }

  .app-contact-cursor:after {
    display: none !important;
  }
  .app-title:after {
    display: none !important;
  }
}

.app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #f90;
  font-family: var(--font-family-almarai);
  font-size: 26px;
}

.app-title:after {
  content: "";
  display: block;
  position: absolute;

  bottom: -39px;
  width: 25px;
  height: 4px;
  background: #f90;
}

.app-title:not(:dir(rtl)):after {
  content: "";
  display: block;
  position: absolute;
  left: 14px;
  bottom: -39px;
  width: 25px;
  height: 4px;
  background: #f90;
}

.app-contact-cursor:not(:dir(rtl)):after {
  content: "";
  display: block;
  position: absolute;
  left: 63px;
  width: 25px;
  height: 4px;
  background: #f90;
}

.app-contact-cursor:after {
  content: "";
  display: block;
  position: absolute;
  right: 50px;
  bottom: 109px;
  width: 25px;
  height: 4px;
  background: #f90;
}
.app-contact {
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  cursor: default;
  font-family: var(--font-family-almarai);
  font-size: 12px;
  color: #ede8e8;
}

.app-contact-cursor {
  line-height: 1.5;
  margin-top: auto;
  cursor: pointer;
  font-family: var(--font-family-almarai);
  font-size: 12px;
  color: #ede8e8;
}
.app-contact-cursor:hover {
  color: #f90;
  text-decoration: underline;
}
.app-form-group {
  margin-bottom: 15px;
}

.app-form-group.message {
  margin-top: 40px;
}

.app-form-group.buttons {
  margin-bottom: 0;
  padding: 6px 0px 6px 0px;
}

.app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  font-family: var(--font-family-almarai);
  border-bottom: 1px solid #666;
  color: #ddd;
  font-size: 14px;
  text-transform: uppercase;
  outline: none;
  transition: border-color 0.2s;
}

.app-form-control::placeholder {
  color: #666;
}

.app-form-control:focus {
  border-bottom-color: #ddd;
}

.app-form-button {
  background: none;
  border: none;
  margin: -5px;
  color: #f90;
  font-family: var(--font-family-almarai);
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.app-form-button:hover {
  color: #ffffff;
}

.credits {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
}

.credits-link {
  display: flex;
  align-items: center;
  color: #fff;
  font-family: var(--font-family-almarai);
  font-weight: bold;
  text-decoration: none;
}

.dribbble {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

@media screen and (max-width: 520px) {
  .screen-body {
    flex-direction: column;
  }

  .screen-body-item.left {
    margin-bottom: 30px;
  }

  .app-title {
    flex-direction: row;
  }

  .app-title span {
    margin-right: 12px;
  }

  .app-title:after {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .screen-body {
    padding: 40px;
  }

  .screen-body-item {
    padding: 0;
  }
}

.social-media-ContactUs:not(:dir(rtl)) {
  position: absolute;
  bottom: 9px;
  left: 41px;
}
.social-media-ContactUs {
  position: absolute;
  bottom: 9px;
  right: 41px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.social-media-email {
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
}

.ContactUs-flex {
  width: 100%;
  color: white;
  justify-content: space-evenly;
}
