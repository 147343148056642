@media (max-width: 767px) {
  .navbar {
    display: none !important;  
  }
}

:root {
  --black: #000000;
  --white: #ffffff;
  --neon-carrot: #f89b32;
  --font-size-xxxl: 20.6px;
  --font-size-m: 22px;
  --font-size-xxxl2: 32px;
  --font-size-xl: 35px;
  --font-family-almarai: "Almarai", Helvetica;
  --font-family-tajawal: "Tajawal", Helvetica;
}

.navbar-link-text.almarai-normal-white-20-6px {
  position: relative;
}

.navbar-link-text.almarai-normal-white-20-6px::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 100%;
  border-radius: 27px;
  height: 4px;
  background-color: transparent;
  transition: background-color 0.8s ease;
}

.navbar-link-text.almarai-normal-white-20-6px:hover::after {
  background-color: #f90;
  /* Change the color on hover */
}

.navbar-link-text.almarai-normal-white-20-6px.active::after {
  background-color: #f90;
}

.navbar-link-text.almarai-normal-white-20-6px.active-title::after {
  background-color: rgb(255, 255, 255);

  border-radius: 0px;
  font-size: 29px;
  /* Color for active link */
}

.img-container {
  width: 92rem;
  max-width: 100%;
  margin: 0 auto;
}

* {
  box-sizing: border-box;
}

.re-design-ebdaa-sa-2 {
  z-index: 555;
  align-items: flex-start;
  /* background-color: var(--white); */
  display: flex;
  /* height: 1763px; */
  width: 100%;
}

.hidden,
.hidden * {
  pointer-events: none;
  visibility: hidden;
}

.heder {
  align-items: flex-start;
  /* background: linear-gradient(180deg, rgba(51, 51, 51, 0.6) 0%, rgba(129, 130, 133, 0) 100%); */
  display: flex;
  height: 95px;
  left: 0;
  justify-content: space-between;
  width: 100%;
  padding: 35px 33.8px 35px 13.8px;
  position: fixed;
  top: 0;
  z-index: 2;
}

.heder:not(:dir(rtl)) {
  padding: 35px 13.8px 35px 33.8px;
}

.layer_1 {
  cursor: pointer;
  height: 35px;
  margin-top: -10px;
  min-height: 35px;
  max-height: 35px;

}

.layer_1-en {
  cursor: pointer;
  height: 69px;
  min-height: 69px;
  max-height: 69px;

  margin-top: -26px;
  max-width: 155px;
}

.layer_1:hover  { 
   transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  transform: scale(1.1); /* Example: Increase size of the image on hover */
}
.layer_1-en:hover  {
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  transform: scale(1.1); /* Example: Increase size of the image on hover */
}
@keyframes thumb_img {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}



.layer_2 {
  height: 41px;
  top: -22px;
  max-height: 41px;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .layer_2 {
    height: 30px;
    top: -15px;
    right: 10px;
  }
}

.re-design-ebdaa-sa-2 .frame {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
}

.frame-75971 {
  gap: 11.49px;
  height: 24px;
}

.en {
  /* opacity: 0; */
  color: var(--white);
  padding-right: 4rem;
  font-family: var(--font-family-tajawal);
  font-size: 14px;
  top: -1px;
  left: 1.23rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.en:not(:dir(rtl)) {
  left: -1.23rem;
  padding-right: 1rem;
}
.en:hover {
  color: #f90;
}
.group-2 {
  height: 24.03px;
  margin-bottom: -0.01px;
  margin-top: -0.01px;
  position: relative;
  width: 24.03px;
}

.group-1 {
  align-items: flex-start;
  background-color: #f4f5f8;
  border-radius: 12.01px;
  display: flex;
  height: 24px;
  min-width: 24px;
  padding: 2.7px 2.7px;
}

.subtract {
  height: 18px;
  width: 14px;
}

.component-3 {
  height: 36.31px;
  margin-bottom: -6.16px;
  margin-top: -6.16px;
  position: relative;
  width: 36.31px;
}

.union {
  height: 19px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 19px;
}

.frame-162471 {
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.09px;
  height: 25.75px;
}

.line-1 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  margin-bottom: -1.7px;
  margin-left: -1.03px;
  margin-right: -1.03px;
  position: relative;
  width: 100%;
}

.text-7 {
  font-size: var(--font-size-xxxl);
  font-weight: 400;
  line-height: normal;
  margin-top: -1.2px;
  position: relative;
  text-align: left;
  width: fit-content;
}

.re-design-ebdaa-sa-2 .text {
  color: var(--neon-carrot);
  direction: rtl;
  font-family: var(--font-family-almarai);
  letter-spacing: 0;
  white-space: nowrap;
}

.navbar {
  align-items: center;
  color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32.32px;
  justify-content: center;
  position: relative;
}
.navbar:not(:dir(rtl)) {
   margin-left: -16%; 
}
.almarai-normal-white-20-6px {
  font-family: var(--font-family-almarai);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.navbar-link-text {
  direction: rtl;
  cursor: pointer;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.03px;
  position: relative;
  text-align: left;
  white-space: nowrap;
  width: fit-content;
}

.view {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  left: 909px;
  min-height: 52px;
  position: fixed;
  top: 151px;
  width: 106px;
  z-index: 3;
}
.white-background {
  background-color: white;
  color: #000;
  transition: background-color 0s ease; /* Optional: Add transition effect for smooth color change */
}

.white-background-ss {
  color: #000;
}

.rectangle-147852 {
  background-color: var(--white);
  height: 4px;
  width: 104px;
}

.text-8 {
  font-size: var(--font-size-xl);
  font-weight: 700;
  line-height: 46.2px;
  margin-left: 0.07px;
  min-width: 104px;
}

.overlap-group {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 130px;
  width: 100%;
  z-index: 100;
}

@media only screen and (max-width: 576px) {
  .overlap-group {
    min-height: 90px;
  }
}

.flex-row-1 {
  align-items: flex-start;
  display: flex;
  gap: 1245px;
  height: 34px;
  min-width: 1832px;
}

.social-media {
  align-items: center;
  align-self: flex-end;
  display: flex;
  height: 30px;
  min-width: 162px;
}

.vector-2 {
  height: 27px;
  margin-bottom: 0.84px;
  margin-left: 20px;
  width: 27px;
}

.vector-1 {
  align-self: flex-end;
  height: 29px;
  margin-left: 21px;
  width: 29px;
}

.vector-3 {
  height: 22px;
  margin-left: 19px;
  margin-top: 3.51px;
  width: 31px;
}

.vector {
  align-self: flex-start;
  height: 29px;
  width: 15px;
}

.text-1 {
  font-size: var(--font-size-m);
  font-weight: 400;
  line-height: normal;
  min-height: 25px;
  min-width: 425px;
}

.flex-row {
  align-items: center;
  background-image: url(https://cdn.animaapp.com/projects/65b7773ed7074b3f4c90262e/releases/65be38509ed278562020f2bb/img/construction-silhouette-1.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 1680px;
  min-width: 1920px;
  padding: 0.6px 0;
}

.mobile-container {
  max-width: 480px;
  margin: auto;
  background-color: #555;
  color: white;
  border-radius: 10px;
}

.topnav {
  overflow: hidden;
  background-color: #333;
  position: relative;
}

.topnav #myLinks {
  display: none;
  position: absolute;
}

.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

.topnav a.icon {
  background: black;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.blockView {
  position: fixed;
  height: 15rem;
  left: 0;
  margin-top: 42px;
  background-color: #1c1c1c;
  width: 100%;
  justify-content: space-around;
  display: flex;
}
.noneblockView {
  display: none;
}
.navbarMobile {
  width: 100%;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-align: center;
}
 

.icon-humberger {
  display: none;
  color: #575953;
  cursor: pointer;
  font-size: 24px;
  margin-top: -6px;
}

.icon-humberger:hover {
  color: #f90;
}

@media screen and (max-width: 768px) {
  .icon-humberger {
    display: block;
  }
}
