@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Almarai:400,700|Tajawal:500");

@font-face {
  font-family: "ArbFONTS-Almarai-Light";
  src: url("./assets/font/ArbFONTS-Almarai-Light.ttf") format("truetype");
}

.color-epda {
  color: #f89b32 !important;
}
.color-white {
  color: #ffffff;
}
body {
  margin: 0;
  font-family: "Almarai", sans-serif;
  background-size: cover;
  background-color: #1c1c1c;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #f90;
  border-radius: 12px;
}

/* Apply gradient background */
::-webkit-scrollbar-thumb {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer;
}

.x-icon {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

#drawer {
  opacity: 0;
  background: #1a1a1a;
  overflow: auto;
  box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.24);
  height: 100vh;
  z-index: 1000;
  padding: 20px;
  position: fixed;
  right: auto; /* Reset right positioning */
  left: 0; /* Position from the left side */
  top: 95px;
  transform: translateX(-100%); /* Slide in from the left */
  transition: transform 300ms ease-in-out;
  width: 37%;
}

/* RTL specific styles */
:dir(rtl) #drawer {
  left: auto; /* Reset left positioning */
  right: 0; /* Position from the right side */
  transform: translateX(100%); /* Slide in from the right */
}



@media screen and (max-width: 768px) {
  #drawer {
    width: 370px;
  }
}
@media screen and (max-width: 768px) {
  #drawer {
    width: 370px;
  }
}
@media screen and (max-width: 576px) {
  #drawer {
    width: 370px;
  }
}

@media screen and (max-width: 1150px) {
  .ourmassage:not(:dir(rtl)) {
    padding: 22px 2rem 2rem 2rem !important;
    height: auto;
    background-size: cover;
    background-color: #141313;
    color: #ffffff;
  }
  #drawer {
    width: 45%;
  }

}
#overlay {
  background: black;
  bottom: 0;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 250ms, visibility 0s;
  visibility: hidden;
  width: 100vw;
  z-index: 2;
}

#body.show-drawer {
  #drawer {
    transform: translateX(0%);
    opacity: 4;
  }

  #overlay {
    opacity: 0.5;
    z-index: 555;
    visibility: visible;
  }
}

.img-item-wrpaer {
  /* Initial state */
  opacity: 0;
  transform: translateY(20px);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.img-item-wrpaer.show {
  /* Final state */
  opacity: 1;
  transform: translateY(0);
  transition-delay: 8ms;
}

.img-item-wrpaer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* width: 100%; */
}

@media screen and (max-width: 1280px) {
  .img-item-wrpaer {
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 1392px) {
  .img-item-wrpaer {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .img-item-wrpaer {
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1393px) and (max-width: 1407px) {
  .img-item-wrpaer {
    align-items: center;
    justify-content: center;
  }
}

.img-item {
  max-width: 342px;
  min-height: 342px;
  width: 342px;
  height: 242px;
  cursor: pointer;
}

.img-item-uper {
  position: absolute;
  max-width: 342px;
  min-height: 342px;
  width: 336px;
  margin: 0px 3px 0px 3px;
}
.slider_img {
  /* box-shadow: 1px -8px 15px 9px rgb(156 142 142 / 8%); */
  max-width: 498px;
  width: 498px;
  height: 428px;
  margin: 0px 3px 0px 3px;
}

@media screen and (max-width: 768px) {
  .slider_img {
    box-shadow: 1px -8px 15px 9px rgb(156 142 142 / 8%);
    max-height: 320px;
    max-width: 320px;
    width: 320px;
    margin: 0px 3px 0px 3px;
  }
}
@media screen and (max-width: 576px) {
  .slider_img {
    box-shadow: 1px -8px 15px 9px rgb(156 142 142 / 8%);
    max-height: 320px;
    max-width: 320px;
    width: 320px;
    margin: 0px 3px 0px 3px;
  }
}

.main-text-slider {
  padding-bottom: 2px;
  padding-top: 2rem;
  color: #ffffff;
  font-size: 18px;
}
.about-text-slider {
  padding-bottom: 3rem;
  margin-bottom: 5rem;
  color: #ffffff;
  font-weight: lighter;
  font-size: 14px;
}
.about-title {
  text-transform: capitalize;
  line-height: 1.6;
  max-width: 492px;
  font-family: "ArbFONTS-Almarai-Light", sans-serif;
}
.img-item-text {
  position: absolute;
  right: 36px;
  bottom: 16px;
  color: white;
  font-size: 24px;
  width: 300px;
  font-weight: 500;
  text-align: end;
  margin-bottom: 12px;
}

.center-contenar {
  display: flex;
  justify-content: center;
  width: 100%;
}
.center-contenar-mobile {
  display: none;
}
@media only screen and (max-width: 576px) {
  .boxcenter-contenar {
    display: none;
  }

  .center-contenar-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}

.flex-view {
  align-items: baseline;
  display: flex;
}

.slideshow-container {
  overflow: hidden;
  min-width: 328px;
  position: relative;
}

@media only screen and (max-width: 576px) {
  .slideshow-container {
    max-width: max-content;
    overflow: hidden;
    min-width: 328px;
    position: relative;
  }
}
@media only screen and (max-width: 768px) {
  .slideshow-container {
    max-width: max-content;
    overflow: hidden;
    min-width: 328px;
    position: relative;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.fade {
  width: 511px;
  height: 390px;
  cursor: pointer;
  object-fit: cover;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

@media only screen and (max-width: 576px) {
  .fade {
    width: 320px;
    height: 320px;
    cursor: pointer;
    object-fit: cover;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
}
@media only screen and (max-width: 768px) {
  .fade {
    width: 320px;
    height: 320px;
    cursor: pointer;
    object-fit: cover;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
}

.fade-out {
  opacity: 0;
  transform: scale(0.9);
}

.fade-in {
  opacity: 1;
  transform: scale(1);
}
.back-button,
.Next-button {
  width: 32px;
  margin-left: 10px;
  margin-right: 10px;
  height: 32px;
}
.Next-button:not(:dir(rtl)) {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.back-button:not(:dir(rtl)) {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.mt-2 {
  margin-top: 2rem;
}
.cENTER {
  width: 100%;
  display: flex;
  justify-content: center;
}

.flex-center-w {
  display: flex;
  justify-content: center;
  width: 100%;
}


.scrollBootn{
  -webkit-filter: grayscale(56%);
  -moz-filter: grayscale(56%);
  -ms-filter: grayscale(56%);
  filter: grayscale(56%);
  filter: rgb(255, 255, 255);
  right: 27px !important;
}

.scrollBootn:hover {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
}

.Toastify
{
z-index: 88888 !important;
}