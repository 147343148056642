.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 90%);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.lightbox.open {
  display: flex;
}

.lightbox img {
  max-width: 90%;
  max-height: 90%;
}

.close-btn {
  position: absolute;
  top: 19px;
  right: 25px;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  transition: opacity 0.3s;
}

.prev-btn:hover,
.next-btn:hover {
  opacity: 0.7;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
}

.gallery img {
  width: 100px;
  height: 100px;
  margin: 5px;
  cursor: pointer;
}

.gallery img:hover {
  opacity: 0.7;
}
