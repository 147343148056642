.OurProjects-wrapper {
  background-image: url("./../../assets/images/background_img/project_back.png");
  background-size: cover;
  font-family: "Almarai", sans-serif;
  background-color: #1c1c1c;
}

.flex-center {
  display: flex;
  width: 100%;
  justify-content: center;
}

.primary-color {
  color: #f90;
}

.img-container {
  width: 92rem;
  max-width: 100%;
  margin: 0 auto;
}

 

@keyframes thumb_img {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes thumb_img {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.15);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes thumb_img {
  0% {
    -moz-transform: scale(1);
  }

  50% {
    -moz-transform: scale(1.15);
  }

  100% {
    -moz-transform: scale(1);
  }
}

.relative-position {
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
}

.relative-position:hover .img-item {
  animation: thumb_img 10s infinite;
  -moz-animation: thumb_img 10s infinite;
  -webkit-animation: thumb_img 10s infinite;
}

.view-mote {
  background: none;
  border: 1px solid #f90;
  padding: 15px;
  color: #f90;
  font-family: var(--font-family-almarai);
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.view-mote:hover {
  background: rgba(238, 235, 235, 0.16);
}

.container-bbt-view-more {
  padding-bottom: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.title-text-project {
  font-size: 18px;
}

.title-description-project {
  color: #f0f8ffd1;
  margin-top: 10px;
  font-size: 14px;
}
