.carousel {
  position: relative;
  display: block;
  overflow: hidden;
}
.mian-carousel {
  position: relative;
  display: block;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .carousel {
    height: 100vh;
  }
}

@media only screen and (max-width: 576px) {
  .carousel {
    height: 201px !important;
    margin-top: 6rem;
  }
}

.carousel img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.5);
  /* transition: opacity 0.9s ease-in-out */
  /* transition: width 2s, height 2s, transform 2s; */
  /* transition: transform 0.45s cubic-bezier(0, 0, 0.21, 1); */
  /* transition-timing-function: linear; */
}
.carousel img.active {
  opacity: 1;
  animation: slider_img 45s infinite, fade 6.5s infinite;
  -webkit-animation: slider_img 45s infinite, fade 6.5s infinite;
  -moz-animation: slider_img 45s infinite, fade 6.5s infinite;
  animation: slider_img 45s infinite, fade 6.5s infinite;
}

@keyframes slider_img {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 0.4;
  }
  20%,
  80% {
    opacity: 1;
  }
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
}

.carousel-item img {
  width: 100%;
  height: auto;
  display: block;
}

/* @-webkit-keyframes slider_img {
  0% {
      -webkit-transform: scale(1);
  }
  50% {
      -webkit-transform: scale(1.5);
  }
  100% {
      -webkit-transform: scale(1);
  }
} */

/* @-moz-keyframes slider_img {
  0% {
      -moz-transform: scale(1);
  }
  50% {
      -moz-transform: scale(1.5);
  }
  100% {
      -moz-transform: scale(1);
  }
} */

/*Carousel arrows*/
.carousel {
  position: relative;
  display: block;
  overflow: hidden;
}

.carousel i {
  position: absolute;
  top: 35vh;
  color: #fff;
  font-size: 32px;
  cursor: pointer;
  z-index: 1;
}

.carousel .prev {
  top: 44%;
  cursor: pointer;
  right: 28px;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 55;
  background-size: 40px;
  width: 44px;
  height: 44px;
  -webkit-filter: grayscale(56%);
  -moz-filter: grayscale(56%);
  -ms-filter: grayscale(56%);
  filter: grayscale(56%);
  filter: rgb(255, 255, 255);
}
.carousel .prev:hover,
.carousel .next:hover {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);

}
.carousel .next {
  cursor: pointer;
  top: 44%;
  position: absolute;
  background-size: 40px;
  z-index: 55;
  background-repeat: no-repeat;
  width: 44px;
  height: 44px;
  -webkit-transform: scaleX(-1);
  -webkit-filter: grayscale(56%);
  -moz-filter: grayscale(56%);
  -ms-filter: grayscale(56%);
  filter: grayscale(56%);
  filter: rgb(255, 255, 255);
  left: 28px;
  transform: scaleX(-1) !important;
}

@media only screen and (max-width: 576px) {
  .carousel .prev,
  .carousel .next {
    width: 31px;
    height: 31px;
    background-size: 30px;
  }
}

/*Carousel indicators*/
.carousel-indicators {
  display: none;
  position: absolute;
  top: auto;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  /* display: flex; */
  gap: 8px;
  z-index: 4;
}

.carousel-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #fff;
  cursor: pointer;
}

.carousel-indicator.active {
  background-color: #fff;
  border: 1px solid #fff;
}

/* For mobile screens */
@media (max-width: 767px) {
  .carousel {
    height: 89vh !important;
  }

  .carousel img {
    height: -webkit-fill-available;
    object-fit: cover;
  }

  .carousel-indicators,
  .carousel-indicator {
    display: none;
  }

  .carousel i {
    top: 17vh;
  }
}
/* Carousel.css */

/* Carousel.css */

/* Loading screen styles */
.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: fadeOut 3s forwards; /* Use animation instead of transition */
}

.anmationlogo {
  width: 50rem;
  animation: fadeOut 155s forwards; /* Use animation instead of transition */
}
.loading-logo {
  color: white;
  font-size: 2rem;
  /* width: 17rem;
  height: 17rem; */
  /* Additional styles for your logo */
}

/* Additional styles for carousel */

/* Keyframes for fade-out animation */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  /* 75% {
    opacity: 0.25;
  }
  85% {
    opacity: 0.20;
  }
  95% {
    opacity: 0.15;
  }
  90% {
    opacity: 0.10;
  } */
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 576px) {

  .anmationlogo
{
  width: 25rem;
  height: 25rem;
}

 
}



