.About-wrapper {
  font-family: "Almarai", sans-serif;
  background-size: cover;
  background-color: #1c1c1c;
}

.about-wraper-head {
  display: flex;
  color: rgb(255, 255, 255);
  width: 100%;
  margin-bottom: 30px;
  font-size: 20px;
  height: 45px;
  width: 25rem;
  justify-content: space-around;
}

.d-flex-center {
  justify-content: center;
  display: flex;
  width: 100%;
}

.w-40 {
  display: flex;
  width: 53rem;
  justify-content: space-around;
}

.w-40 .navbar-link-text.almarai-normal-white-20-6px::after {
  bottom: 8px;
}

@media only screen and (max-width: 576px) {
  .navbar-link-text {
    font-size: 14px !important;
  }
  .navbar-link-text:not(:dir(rtl)) {
    font-size: 14px !important;
  }
}
.how-uss-text {
  font-family: var(--font-family-almarai);
  color: #f89b32;
  font-size: 21px;
}

.how-uss-text-about-us {
  font-family: var(--font-family-almarai);
  color: #f89b32;
  display: flex;
  justify-content: center;
  font-size: 21px;
}

.how-uss-text-white {
  font-family: var(--font-family-almarai);
  color: #ffffff;
  font-size: 16px;
}
.how-uss {
  /* position: relative;
  font-family: var(--font-family-almarai);
  line-height: 2;
  text-align: justify;
  width: auto;
  background-color: #ffffff1c;
  color: white;
  min-height: 20rem;
  margin: 3rem;
  padding: 2rem; */
}

.how-wrpaer {
  display: block;
  /* justify-content: center;
  width: 100%; */
}

.how-color {
  font-family: var(--font-family-almarai);
  color: #f89b32;
}
@media only screen and (max-width: 576px) {
  .how-color {
    font-size: 15px;
  }
}
.how--img,
.how--img2,
.how--img3 {
  height: max-content;
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;

  background-position: bottom;
}

@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 768px) {
}

.how--img {
  background-image: url(../../assets/images/background_img/howusback.png);
}

.d-flex_2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  /* Apply the following styles only for screens up to 767 pixels wide (mobile) */
  .d-flex_2 {
    justify-content: center;
  }
}
.flexcenter {
  display: flex;
  justify-content: center;
}

.flexcenter-how {
  line-height: 2.5;
  padding: 1rem 3rem 1rem 3rem;
}

.parnner-img,
.parnner-img2,
.parnner-img4,
.parnner-img3 {
  height: 16rem;
  cursor: pointer;
  width: 24rem;
  margin: 1.2rem;
  background-position: 48% 54%;
  background-repeat: no-repeat;
  background-color: #ffffff1c;
  background-size: 74%;
  border-radius: 13% 87% 75% 25% / 30% 68% 32% 70%;
}
.parnner-img4 {
  background-position: 46% 62% !important;
}

@media only screen and (max-width: 768px) {
  .parnner-img,
  .parnner-img2,
  .parnner-img4,
  .parnner-img3 {
    height: 12rem;
    cursor: pointer;
    width: 20rem;
    margin: 1.2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #ffffff1c;
    background-size: 74%;
    border-radius: 13% 87% 75% 25% / 30% 68% 32% 70%;
  }
}

@media only screen and (max-width: 576px) {
  .parnner-img,
  .parnner-img2,
  .parnner-img4,
  .parnner-img3 {
    height: 11rem;
    cursor: pointer;
    width: 15rem;
    margin: 1.2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #ffffff1c;
    background-size: 74%;
    border-radius: 13% 87% 75% 25% / 30% 68% 32% 70%;
  }
}

.parnner-img:hover,
.parnner-img2:hover,
.parnner-img3:hover,
.parnner-img4:hover {
  transition: background-color 0.8s ease;
  background-color: #f90;
}

.parnner-img {
  background-image: url(../../assets/images/CardPictures/Our_partners1.png);
}

.parnner-img2 {
  background-image: url(../../assets/images/CardPictures/Our_partners2.png);
}

.parnner-img3 {
  background-image: url(../../assets/images/CardPictures/Our_partners3.png);
}

.w-50 {
  width: 50%;
  padding: 0rem 3rem 2rem 4rem;
}

.d-flex {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .d-flex {
    flex-wrap: wrap;
  }
  .w-50 {
    width: 100%;
    padding: 0px;
    font-size: 12px;
  }
}
