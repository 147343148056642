.Vacancies-wrapper {
  .input-wrapper {
    min-height: 56px;
    margin-bottom: 2rem;
    border: 1.22px solid;
    border-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  .input-wrapper input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    color: #b8b8b8;
    font-size: 17px;
    font-weight: 400;
    line-height: 39.1px;
    font-family: "Almarai", Helvetica;
    letter-spacing: 0;
    direction: rtl;
    outline: none;
  }

  .part-Vacancies {
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 5rem;
    justify-content: space-evenly;
    display: flex;
  }
  .part-v {
    width: 50%;
    padding: 0rem 3rem 0rem 3rem;
  }
  .part-v-2 {
    width: 35%;
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    padding: 76px 46px 57px 46px;
  }

  .input-wrapper textarea {
    width: 100%;
    min-height: 56px;
    height: 371px;
    width: 601px;
    padding-top: 15px;
    min-width: 40px;
    height: 100%;
    border: none;
    background: transparent;
    color: #b8b8b8;
    font-size: 18px; /* Adjust font size as needed */
    font-weight: 400;
    font-family: "Almarai", Helvetica;
    letter-spacing: 0;
    direction: rtl;

    outline: none;
  }

  .text-glopel {
    color: #f90;
    font-family: "Almarai", Helvetica;
  }

  .text-gree {
    color: #b9b9b9;
    font-family: "Almarai", Helvetica;
    font-size: 13px;
  }

  .button-wrapper {
    width: 130px;
    height: 40px;
    position: relative;
    overflow: hidden;
  }

  .overlap-5 {
    width: 123px;
    justify-content: center;
    display: flex;
    cursor: pointer;
    height: 40px;
    background-color: #ffffff33;
    border: 1.22px solid;
    border-color: #f89b32;
    align-items: center;
    color: white;
    font-family: "Almarai", Helvetica;
  }
  .overlap-5:hover {
    background-color: #ffffff69;
  }

  .text-wrapper-10 {
    color: #f89b32;
    font-size: 16px;
    font-weight: 400;
    font-family: "Almarai", Helvetica;
    letter-spacing: 0;
    direction: rtl;
  }

  .file-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .d-flex {
    display: flex;
  }

  .uplodetext {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 17px;
    font-family: "Almarai", Helvetica;
    padding-left: 17px;
    color: #b8b8b869;
  }
  .mext {
    font-size: 26px;
    font-weight: 900;
    padding: 0px 0px 42px 0px;
  }
  .main-titele {
    text-align: center;
    color: #f19e38;
    font-size: 2rem;
    font-family: "Almarai", Helvetica;
    font-weight: 900;
  }
  .sub-titele {
    text-align: center;
    color: #ffffff;
    font-size: 22px;
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-family: "Almarai", Helvetica;
    font-weight: 300;
  }
}
.item-poient {
  margin-top: 15px;
  margin-bottom: 15px;
}
.title-item-poient {
  font-family: "Almarai", Helvetica;
  color: #f19e38;
  font-size: 19px;
  font-weight: 600;
}
.des-item-poient {
  font-family: "Almarai", Helvetica;
  color: #ffffff;
  font-size: 17px;
  line-height: 1.9;
}
.title-item-poient-white {
  font-family: "Almarai", Helvetica;
  color: #ffffff;
  font-size: 15px;
}

.item-row {
  font-family: "Almarai", Helvetica;
  color: #ffffff;
  font-size: 17px;
  line-height: 1.9;
}
