.Careers {
}
.Careers_box {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 12px;
  justify-content: center;
}

.Careers_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  margin: 1rem;
  padding: 16px;
  height: 153px;
  border: 1px solid #ffffff;
}
.job-name {
  line-height: 1.3em;
  font-size: 17px;
  color: #f90;
  font-family: var(--font-family-almarai);
}
.job-part {
  font-size: 12px;
  color: rgb(255, 255, 255);
  font-family: var(--font-family-almarai);
}
.job-loaction {
  font-size: 12px;
  color: rgb(255, 255, 255);
  font-family: var(--font-family-almarai);
}
.more-det {
  cursor: pointer;
  font-size: 14px;
  color: #f90;
  font-family: var(--font-family-almarai);
}
.arraw {
  color: rgb(255, 255, 255);
}
.Careers_img {
  position: relative;
}

@media screen and (max-width: 768px) {
  .Careers_img div {
    object-fit: cover !important;
    background-size: cover !important;
    width: 100%;
    height: 15rem !important;
    background-repeat: no-repeat;
    background-position: unset !important;
  }
}

.area {
  /* background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8); */
  width: 100%;
  height: 100vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #f89b3299;
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    /* border-radius: 90%; */
    border-radius: 0%;
  }
}

.join-use-box {
  width: 63%;
  max-width: 55rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

@media only screen and (max-width: 576px) {
  .join-use-box {
    margin-top: 10%;
  }

  .join-use-box {
    width: 83%;
    max-width: 55rem;
    height: auto;
    min-height: 20rem;
    display: flex;
  }
}

.text-white {
  color: white;
  line-height: 1.9;
  font-size: 15px;
  font-family: var(--font-family-almarai);
}
.text-ebd {
  color: #f89b32;
  font-size: 21px;
  font-weight: 600;
  font-family: var(--font-family-almarai);
}

.details-carre {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.text-ebd-2 {
  color: #f89b32;
  margin-bottom: 19px;
  margin-top: 15px;
  font-size: 21px;
  font-family: var(--font-family-almarai);
}

.det-box {
  width: 63%;
  max-width: 55rem;
  min-height: 29rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.det-box-wrapper {
  .input-wrapper {
    min-height: 56px;
    margin-bottom: 2rem;
    border: 1.22px solid;
    border-color: #ffffff;
    width: inherit;
    display: flex;
    align-items: center;
    margin: 8px 0px 8px 8px;
    width: -webkit-fill-available;
    padding: 0 10px;
  }

 


  .input-wrapper:not(:dir(rtl)) {
    margin: 8px 8px 8px 0px;
  }


  @media only screen and (max-width: 576px) {
    .input-wrapper
    {
      margin-top: 20px;
    }


  }
  .input-wrapper input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    color: #b8b8b8;
    font-size: 17px;
    font-weight: 400;

    line-height: 39.1px;
    font-family: "Almarai", Helvetica;
    letter-spacing: 0;
    direction: rtl;
    outline: none;
  }

  .part-Vacancies {
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 5rem;
    justify-content: space-evenly;
    display: flex;
  }
  .part-v {
    width: 50%;
    padding: 0rem 3rem 0rem 3rem;
  }
  .part-v-2 {
    width: 35%;
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    padding: 76px 46px 57px 46px;
  }

  .input-wrapper textarea {
     padding: 0 10px !important;
    width: 100%;
    min-height: 56px;
    height: 371px;
    padding-top: 15px;
    min-width: 40px;
    height: 100%;
    border: none;
    background: transparent;
    color: #b8b8b8;
    font-size: 18px; /* Adjust font size as needed */
    font-weight: 400;
    font-family: "Almarai", Helvetica;
    letter-spacing: 0;
    direction: rtl;
    width: inherit;
    width: -webkit-fill-available;
    outline: none;
  }

  .text-glopel {
    color: #f90;
    font-family: "Almarai", Helvetica;
  }

  .text-gree {
    color: #b9b9b9;
    font-family: "Almarai", Helvetica;
    font-size: 13px;
  }

  .button-wrapper {
    width: 130px;
    height: 40px;
    position: relative;
    overflow: hidden;
  }

  .overlap-5 {
    width: 130px;
    justify-content: center;
    display: flex;
    cursor: pointer;
    height: 40px;
    background-color: #ffffff33;
    border: 1.22px solid;
    border-color: #f89b32;
    align-items: center;
    color: white;
    font-family: "Almarai", Helvetica;
  }
  .overlap-5:hover {
    background-color: #ffffff69;
  }

  .text-wrapper-10 {
    color: #f89b32;
    font-size: 16px;
    font-weight: 400;
    font-family: "Almarai", Helvetica;
    letter-spacing: 0;
    direction: rtl;
  }

  .file-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .d-flex {
    display: flex;
  }

  .uplodetext {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 17px;
    font-family: "Almarai", Helvetica;
    padding-left: 17px;
    color: #b8b8b869;
  }
  .mext {
    font-size: 26px;
    font-weight: 900;
    padding: 0px 0px 42px 0px;
  }
  .main-titele {
    text-align: center;
    color: #f19e38;
    font-size: 2rem;
    font-family: "Almarai", Helvetica;
    font-weight: 900;
  }
  .sub-titele {
    text-align: center;
    color: #ffffff;
    font-size: 22px;
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-family: "Almarai", Helvetica;
    font-weight: 300;
  }
}
.d-flex-s {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
@media only screen and (max-width: 576px) {
  .det-box-wrapper {
    margin-top: 3%;
    width: 80%;
  }

  .overlap-5
  {

    
  }
  .d-flex-s {
    display: block;
    width: 100%;
  }
}

.save-vv {
  width: 100%;
  display: flex;
  justify-content: left;
  padding-left: 10px;
}

.save-vv:not(:dir(rtl)) {
  justify-content: right  ;
  padding-right: 10px;
}


@media only screen and (max-width: 576px) {
  .uplodetext {
    margin-top: 1rem;
  }

  .save-vv {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
.text-ebd-2 {
  color: #f89b32;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 21px;
  font-family: var(--font-family-almarai);
}
}
