.HomePage-wrapper {
  overflow: hidden;
  font-family: "Almarai", sans-serif;
}
.about-title {
  line-height: 1.9;
}
.rating_star {
  width: 148.13px;
  height: 51.48px;
}

.main-icon {
  width: 257.13px;
  height: 115.48px;
}
.TABELT-VIEW {
  display: none;
}
.iso-icon {
  width: 217.13px;
  height: 156.48px;
}

.rating_nye {
  width: 98.13px;
  height: 89.48px;
  margin-bottom: 20px;
}
.atraciorpng {
  object-fit: cover;
  background-size: cover;
  min-width: 355px;
  width: 100%;
  height: 335.5px;
}

.review_text {
  color: white;
  font-size: 30px;
  text-align: center;
}

.rating_text {
  margin-top: 1px;
  font-size: 30px;
  text-align: center;
}

.completed_project-text {
  font-size: 30px;
  text-align: center;
}

.rating_value {
  color: #ffffff;
  font-size: 96px;
}

.rating_Top_section_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 276px;
  /* max-width: 1440px; */
  width: 100%;
  background-color: #1c1c1c;
}

.rating_Top {
  flex-direction: column;
  color: #f89b32;
  position: relative;
  object-fit: cover;
  height: 18rem;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rating_Top-1 {
  flex-direction: column;
  color: #f89b32;
  position: relative;
  object-fit: cover;
  height: 22rem;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plis {
  position: absolute;
  color: #f89b32;
  font-size: 86px;
  font-weight: 700;
  right: 18%;
  top: 10%;
}

.rating-box {
  position: relative;
  background-color: #1c1c1c;
  background-image: url(../../assets/images/shapes/building.png);
  background-repeat: no-repeat;
  padding: 20px;
  height: 330px;
  width: 355.5px;
  background-size: cover;
}

.rating-box2 {
  position: relative;
  background-color: #1c1c1c;
  background-image: url(../../assets/images/shapes/building.png);
  background-repeat: no-repeat;
  padding: 21px 21px 21px 20px;
  max-width: 354px;
  max-height: 330px;
  min-width: 354px;
  width: 100%;
  background-size: cover;
}

.rating_img_town {
  height: 330px;
  width: 355.5px;
  object-fit: fill;

  background-repeat: round;
  background-size: cover;
  width: 100%;
  background-image: url(../../assets/images/shapes/loacal_1.png);
}

.footer-home {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-self: center;
  width: 100%;
  min-height: 352px;
  background-color: #1c1c1c;
  background-image: url(../../assets/images/shapes/Group.svg);
  background-repeat: no-repeat;
  background-size: 30rem;
  color: #f89b32;
  text-align: center;
  background-position: right;
  padding-top: 20px;
}

.Social-media-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Social-media-icon {
  height: 29px;
  cursor: pointer;
  width: 29px;
  margin: 10px;
}

.logo-Viosn2030 {
  width: 159.78px;
  height: 106.68px;
}
.saction_2 {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
.saction_3 {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  height: 231px;
}
.centimg {
  object-fit: inherit;
  background-size: cover;
  max-width: 354px;
  height: 231px;
  max-height: 330px;
  min-width: 354px;
  width: 100%;
}

.Mask_view {
  object-fit: cover;
  width: 100%;
  max-height: 340px;
  height: 100%;
}

.Mask_view2 {
  object-fit: cover;
  width: 100%;
  height: 231px;
}

.width-100 {
  width: 100%;
}

.width-height-100 {
  width: 100%;
}

.Mask_view_saction_2 {
  background-size: contain;
  height: 275px;
  width: 100%;
  object-fit: cover;
}

.img_2 {
  height: 332.5px;
}

.img_22 {
  width: 37.5rem;
}

.fethcer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f89b32;
  width: 100%;
  max-width: 355.5px;
}

.fethcer-1 {
  font-size: 40px;
  line-height: 1.5;
  text-align: center;
  font-weight: 900;
}

.fethcer-2 {
  font-size: 19px;
  text-align: center;
}

.how-are-we {
  color: #f89b32;
  font-size: 30px;
  font-weight: 900;
}

.how-are-we-dec {
  color: #ffffff;
  font-size: 15.5px;
  text-align: justify;
  padding-left: 26px;
  line-height: 2.9;
}

.how-are-we-dec:not(:dir(rtl)) {
  line-height: 2.9;
}

.fethcersss {
  position: absolute;
  top: 8px;
  padding: 3px 20px 15px 20px;
}

.cc-dsf {
  position: relative;
}

.center-scer {
  max-width: 354px;
  max-height: 330px;
  min-width: 354px;
  width: 100%;
}

.plis2 {
  position: absolute;
  color: #f89b32;
  font-size: 82px;
  font-weight: 700;
  right: 29%;
  top: 18%;
}

.saction_2,
.saction_3 {
  /* max-width: 1440px; */
  width: 100%;
  margin: 0 auto;
}

.Iso {
  display: contents;
}

.rating_item {
  padding: 25px;
}

.rating_section_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* max-width: 1440px; */
  width: 100%;
  flex-wrap: wrap;
  padding-left: 2%;
  padding-right: 3%;
  min-height: 340px;
  height: fit-content;
  background-color: #1c1c1c;
  background-image: url(../../assets/images/shapes/backk.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #f89b32;
  text-align: center;
  padding-top: 20px;
}

.Social-media-icon:hover {
  cursor: pointer;
}

.Social-media-icon:nth-child(1):hover {
  content: url("./../../assets/images/icon/face-h.svg");
}

.Social-media-icon:nth-child(2):hover {
  content: url("./../../assets/images/icon/insg-h.svg");
}

.Social-media-icon:nth-child(3):hover {
  content: url("./../../assets/images/icon/linkend-h.svg");
}

.Social-media-icon:nth-child(4):hover {
  content: url("./../../assets/images/icon/youtube-h.svg");
}

.mobileview {
  display: none;
}

/* /////////////////////////////////// */
.TABELET-des {
  display: none;
}

@media screen and (max-width: 1280px) {
  .how-are-we-dec {
    font-size: 12px;
  }
}
@media screen and (min-width: 1700px) {
  .Mask_view {
    object-fit: unset;
  }
}
@media screen and (max-width: 1200px) {
  .rating_item {
    padding: 13px;
  }
  .rating_section_wrapper {
    justify-content: space-around;
    padding-left: 0%;
    padding-right: 0%;
  }
}

@media only screen and (max-width: 1073px) {
  .rating-box2 {
    background-repeat: no-repeat;
    padding: 22px 21px 21px 20px;
    max-width: 354px;
    max-height: 330px;
    min-width: 357px;
    width: 100%;
    background-size: cover;
  }

  .TABELET-des {
    display: block;
  }
  .Mask_view2 {
    object-fit: fill;
    width: 100%;
    height: 206px;
  }
  .how-are-we-dec {
    font-size: 0.9rem;
  }

  .TABELT-VIEW {
    display: block;
  }

  /* 
  .mobileview {
    display: block;
  } */
  .saction_2,
  .saction_89798 {
  }
  .saction_3 {
    flex-direction: row-reverse;
    display: inline-flex;
    width: 100%;
    max-height: 207px;
    justify-content: space-between;
  }

  .webview {
    display: none;
  }
  .img_22 {
    width: 100%;
  }
  .centimg {
    object-fit: cover;
    background-size: cover;
    max-width: inherit;
    max-height: 206px;
    min-width: 354px;
    width: 100%;
  }
  .review_text {
    color: white;
    font-size: 22px;
    text-align: center;
  }
  .main-icon {
    width: 159.13px;
    height: 65.48px;
  }
  .iso-icon {
    width: 149.13px;
    height: 106.48px;
  }
  .rating_item {
    padding: 11px;
  }
  .rating_nye {
    width: 81.13px;
    height: 68.48px;
    margin-bottom: 9px;
  }
  .rating_section_wrapper {
    padding-left: 0%;
    padding-right: 0%;
    min-height: 253px;
    background-size: 71rem;
    background-position: top;
  }

  .img_2 {
    width: 100%;
  }
  .width-height-100 {
    display: none;
  }

  .saction_2,
  .saction_89798 {
    display: flex;
    flex-wrap: nowrap;
    width: auto;
    flex-direction: row-reverse;
    width: -webkit-fill-available;
  }

  .Mask_view {
    object-fit: unset;
  }
  .atraciorpng {
    object-fit: fill;
    background-position: center;
    background-size: cover;
    min-width: 355px;
    width: 100%;
    height: 332.5px;
  }
  .plis {
    position: absolute;
    color: #f89b32;
    font-size: 86px;
    font-weight: 700;
    right: 17%;
    top: 10%;
  }
}

@media only screen and (max-width: 982px) {
  .how-are-we-dec {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 767px) {
  .saction_3 {
    max-height: 208px;
  }

  .main-icon {
    width: 128.13px;
    height: 63.48px;
  }
  .Mask_view {
    object-fit: unset;
    height: 238.5px;
  }
  .rating_Top_section_wrapper {
    min-height: auto;
    flex-wrap: wrap;
  }
  .rating_Top-1 {
    height: 311px;
    background-repeat: no-repeat;
    background-size: cover !important;
  }
  .plis {
    position: absolute;
    color: #f89b32;
    font-size: 86px;
    font-weight: 700;
    right: 32%;
    top: 9%;
  }

  .rating-box2 {
    position: relative;
    background-color: #1c1c1c;

    background-image: url(../../assets/images/shapes/building.png);
    background-repeat: no-repeat;
    padding: 21px 21px 21px 20px;
    max-width: 100%;
    max-height: 330px;
    min-width: max-content;
    width: 100%;
    background-size: cover;
  }
  .rating_img_town {
    height: 330px;
    width: 355.5px;
    background-repeat: space;
    margin-top: -2px;
    background-position: center;
    background-size: cover;
    width: 100%;
  }
  .atraciorpng {
    min-width: 155px;
    height: 311px;
    object-fit: cover;
  }
  .img_2 {
    width: 100%;
  }
  .centimg {
    object-fit: inherit;
    background-size: cover;
    max-height: fit-content;
    min-width: auto;
    width: 100%;
    height: 100%;
    max-height: 208px !important;
  }
  .fethcer {
    max-height: 208px !important;
  }
  .rating_section_wrapper {
    justify-content: center;
  }
  .plis2 {
    right: 24%;
    top: 2%;
  }
  .plis {
    position: absolute;
    color: #f89b32;
    font-size: 86px;
    font-weight: 700;
    right: 33%;
    top: 10%;
  }
  .center-scer {
    max-width: none;
    max-height: max-content;
    min-width: max-content;
    width: 100%;
  }
  .how-are-we-dec {
    font-size: 10px;
    padding-left: 0px;
  }
  .img_2 {
    height: auto;
  }
}
@media only screen and (max-width: 576px) {
  .saction_3 {
    max-height: max-content !important;
  }

  .rating_Top-1 {
    height: 217px;
  }

  .Mask_view {
    height: 324px;
  }
  .mobileview {
    display: block;
  }
  .webview {
    display: none;
  }

  .saction_2,
  .saction_3 {
    display: flex;
    flex-wrap: wrap;
    /* width: max-content; */
  }

  .saction_3 {
    height: auto;
  }
  .rating_item-1 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .rating_item-1:not(:dir(rtl)) {
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .rating_section_wrapper {
    justify-content: space-between;
    background-size: 59rem;
    flex-wrap: wrap-reverse;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: -10px;
    min-height: 213px;
    padding-top: 0px;
  }

  /* .rating_item {
    width: 100%;
  } */

  /* .Iso {
    object-fit: fill;
    background-repeat: round;
    background-size: cover;
    flex-wrap: wrap;display: flex;
    width: 100%;
    background-image: url(../../assets/images/background_img/iso-mob.png);
  } */

  .atraciorpng {
    object-fit: fill;
    background-size: cover;
    width: 100%;
    width: 100%;
    height: 287px;
  }
  .img_2 {
    width: 100%;
  }
  .rating_Top-1 {
    background-size: contain;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center;
    height: 192px;
  }

  .rating_img_town {
    height: 287px;
  }

  .centimg {
    object-fit: fill;
    background-size: cover;
    max-width: none;
    height: 287px;
    max-height: 287px !important;
    min-width: 354px;
    width: 100%;
  }
  .img_22 {
    width: 100%;
  }
  .fethcer {
    display: flex;
    height: 192px;
    margin-top: -8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #f89b32;
    width: 100%;
    max-width: none;
  }
  .how-are-we-dec {
    padding-left: 0px;
    font-size: 11px;
    padding-left: 0px;
  }
  .center-scer {
    max-width: none;
    max-height: max-content;
    min-width: max-content;
    width: 100%;
  }
  .plis2 {
    right: 30%;
    top: -2%;
  }
  .rating_Top_section_wrapper {
    flex-wrap: wrap-reverse;
  }
  .plis {
    position: absolute;
    color: #f89b32;
    font-size: 86px;
    font-weight: 700;
    right: 23%;
    top: -22%;
  }
  .rating-box2 {
    padding: 21px 21px 21px 20px;
    max-width: none;
    max-height: min-content;
    margin-top: -3px;
    min-width: 354px;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
  .TABELT-VIEW {
    display: none;
  }

  .rating_Top {
    height: 9.3rem;
  }
  .rating_nye {
    width: 45.13px;
    height: 38.48px;
  }

  .iso-icon {
    width: 117.13px;
    height: 83.48px;
  }

  .scrollBootn {
    -webkit-filter: grayscale(56%);
    -moz-filter: grayscale(56%);
    -ms-filter: grayscale(56%);
    filter: grayscale(56%);
    filter: rgb(255, 255, 255);
    right: 28px;
    width: 31px !important;
    height: 31px !important;
    background-size: 30px;
  }
  .text-ebd {
    margin-bottom: 15px;
  }
  .en {
    font-size: 16px !important;
  }

  .news-s {
    width: 333px;
  }
  .news-img-item-text {
    line-height: 1.5;
    color: #ffffff;
    width: 330px !important;
  }
  .rating_value {
    color: #ffffff;
    font-size: 77px;
  }
  .completed_project-text {
    color: #ffffff;
    font-size: 28px;
  }
  .con-dev {
    margin: 5px;
  }
  .ourviso {
    padding: 1rem 2rem 2rem 2rem !important;
  }
  .dec3dec33ew534 {
    line-height: 25px;
  }

  .news-img-item-wrpaer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .fethcersss {
    position: absolute;
    top: 8px;
    padding: 0px 21px 15px 20px;
  }

  .relative-position {
    position: relative;
    margin: 11px 5px 5px 5px !important;
  }
}

.plis-realtef {
  position: relative;
}
@media only screen and (max-width: 340px) {
  .rating_section_wrapper {
    justify-content: center;
  }
}
