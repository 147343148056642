/* Customize your slider styles here */
.slider-container {
  margin: 20px;
}

/* Customize the styles for the dots */
.slick-dots {
  display: none;
}

.slick-dots li {
  margin: 0 4px;
}

.slick-track {
  background-color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.slick-dots li button {
  font-size: 12px;
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  outline: none;
}

.slick-dots li.slick-active button {
  background-color: #fff;
}
.slick-list {
  background-color: #ffffff00;
}
.slider-container {
  margin: 0px;
  overflow: hidden;
}
.slick-slide img {
  background-color: #ffffff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 207px;
  height: 192px;
}

@media only screen and (max-width: 576px) {
  .slick-slide img {
    width: 60px;
    height: 60px;
  }
  .con {
    width: auto !important;
    height: auto !important;
    max-width:294px  !important;
  }
}

.Slider-prand {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1588px; 
  justify-content: center;
}

.con {
  /* max-width: 275px;
  max-height: 248px; */
  padding: 2rem;
  background-color: white;
  transition: transform 0.3s ease;
}

.con-dev {
  margin: 11px;
}

/* .cnter-item {
} */

.Slider-wrpere {
  display: flex;
  justify-content: center;
}

.relative-position {
  position: relative;
  overflow: hidden;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.relative-position:hover .con {
  transform: scale(1.1); /* Example: Increase size of the image on hover */
}

@keyframes thumb_img {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.con {
  width: 373px !important;
  height: 328px !important;
}

.con-div-img {
  background-repeat: no-repeat;
  background-position: center;
  /* object-fit: cover; */
  background-size: 75%;
}
