.News-wrapper {
  font-family: "Almarai", sans-serif;
  background-size: cover;
  /* background-color: #1c1c1c; */
}

.cloce-icon {
  cursor: pointer;
}

.cloce-icon:hover {
  cursor: pointer;
  background-color: #f1ece626;
  border-radius: 50%;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Almarai:400,700|Tajawal:500");
:root {
  --black: #000000;
  --white: #ffffff;
  --neon-carrot: #f89b32;
  --font-size-xxxl: 20.6px;
  --font-size-m: 22px;
  --font-size-xxxl2: 32px;
  --font-size-xl: 35px;
  --font-family-almarai: "Almarai", Helvetica;
  --font-family-tajawal: "Tajawal", Helvetica;
}

* {
  box-sizing: border-box;
}

.relative-position {
  margin-top: 7px;
  position: relative;
}
.news-img-container {
  /* width: 92rem; */
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 76rem;
}

.news-img-item-wrpaer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
}
@media only screen and (max-width: 1200px) {
  .news-img-item-wrpaer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
}
.news-img-item {
  max-height: 400px;
  height: 316px;
  max-width: 369px;
  width: 330px;
}
.news-img-item-text {
  line-height: 1.5;
  color: #ffffff;
  width: 330px;
}
.top {
  margin-top: 3px;
}
