.footer {
  display: flex;
  width: 100%;
  padding: 2px 1rem;
  height: 50px;
  color: #f89b32;
  justify-content: space-between;
  padding-top: 7px;
  padding-bottom: 7px;
  align-items: center;
  background-color: #1c1c1c;
}

.footer-transparent {
  display: flex;
  width: 100%;
  padding: 2px 1rem;
  height: 50px;
  color: #f89b32;
  justify-content: space-between;
  padding-top: 7px;
  padding-bottom: 7px;
  align-items: center;
  background-color: transparent;
}

.social-media-container {
  display: flex;
  z-index: 5;
  align-items: center;
  justify-content: center;
}

.social-media-icon {
  cursor: pointer;
  height: 15px;
  width: 20px;
  margin: 6px;
}
@media only screen and (max-width: 576px) {

  .social-media-icon {
    cursor: pointer;
    height: 13px;
    width: 13px;
    margin: 6px;
}


}






.copyright {
  display: flex;
  font-family: var(--font-family-almarai);
  justify-content: center;
  font-size: 14px;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .copyright {
    font-size: 12px;
  }
}
@media screen and (max-width: 576px) {
  .copyright {
    font-size: 10px;
  }
}


.social-media-icon:hover {
  cursor: pointer;
}

.social-media-icon:nth-child(1):hover {
  content: url('./../../assets/images/icon/face-h.svg');
}

.social-media-icon:nth-child(2):hover {
  content: url('./../../assets/images/icon/insg-h.svg');
}

.social-media-icon:nth-child(3):hover {
  content: url('./../../assets/images/icon/linkend-h.svg');
}

.social-media-icon:nth-child(4):hover {
  content: url('./../../assets/images/icon/youtube-h.svg');
}
