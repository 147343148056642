.About-New-wrapper {
  display: flex;
  flex-direction: column;
}
.About-First-sec,
.About-scaned-sec {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* flex-wrap: wrap;
  /* flex-wrap: wrap; */
}

.About-First-sec {
  height: 200px;
  background-size: cover;
  background-color: #363535;
  color: #ffffff;
  background-image: url(../../assets/images/shapes/team-business-people-stacking-hands\ 1.png);
}
.mass-sub-text {
  width: 95%;
  /* font-size: 1.2vw; */
  line-height: 1.9;
  color: #f89b32;
}

.main-sub-text {
  font-size: 29px;

  line-height: 2;
  font-weight: 600;
  color: #f89b32;
}
.wi25 {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wi75 {
  font-family: "ArbFONTS-Almarai-Light", sans-serif;
  width: 75%;
  align-items: center;
  font-size: 15px;
  line-height: 2;
  display: flex;
  padding: 11px 36px 13px 31px;
}

.ourviso {
  padding: 1rem 2rem 2rem 2rem;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
}
.ourmassage {
  padding: 2rem 2rem 2rem 2rem;
  height: auto;
  background-size: cover;
  background-color: #141313;
  color: #ffffff;
  background-image: url(../../assets/images/shapes/team-business-people-stacking-hands\ 1.png);
}
.ourmassage:not(:dir(rtl)) {
  padding: 2rem 2rem 2rem 2rem;
  height: auto;
  background-size: cover;
  background-color: #141313;
  color: #ffffff;
  background-image: url(../../assets/images/shapes/team-business-people-stacking-hands\ 1.png);
}




.sett {
  padding: 2rem 2rem 2rem 2rem;
  height: 13.5rem;
  height: auto;
  max-height: 23rem;
  width: 25%;
  background-size: cover;
  background-color: #363535;
  color: #ffffff;
  background-image: url(../../assets/images/shapes/72376771901.png);
}
.ourviso-text {
  color: #f90;
  font-size: 26px;
  font-weight: 600;
  padding-bottom: 1rem;
}
.ourviso-mass {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 1rem;
}
.ourviso-mass:not(:dir(rtl)) {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 3px;
}
.subAbout-scaned-sec {
  display: block;
  width: 50%;
}

.About-last-sec {
  display: flex;
  width: 100%;
}

.logo-vesionn-about {
  /* background-position: center; */
  background-repeat: round;
  height: 400px;
  width: 355.5px;
  /* object-fit: inherit; */
  background-size: cover;
  width: 100%;
  background-image: url(../../assets/images/shapes/Mask\ group.png);
}

.iamger {
  width: 50%;
}

.height50 {
  height: 50%;
}
.rateus {
}
.rateus-text {
  color: #f90;
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 600;
  padding-right: 13px;
  padding-left: 13px;
}

.rateus-list {
  display: flex;
}

.text-list {
  font-size: 16px;
  display: flex;
  line-height: 2;
}

.text-list-point {
  min-width: 5px;
  background-color: #f89b32;
  height: 5px;
  border-radius: 50%;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.rateus-wraper {
  width: 75%;
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 24px 21px 31px 8px;
  flex-direction: column;
}

.line-orange {
  height: 8rem;
  background-color: orange;
  width: 3px;
  margin-top: 2rem;
}

/* Media query screens */

@media only screen and (max-width: 1150px) {
  .wi75 {
    width: 75%;
    font-size: 12.9px;
  }
}

@media only screen and (max-width: 992px) {
  #drawer {
    width: 70%;
  }

  .parnner-img,
  .parnner-img2,
  .parnner-img4,
  .parnner-img3 {
    height: 12rem;
    cursor: pointer;
    width: 17rem;
  }

  .wi25 {
    width: 28%;
  }
  .main-sub-text {
    font-size: 26px;
    line-height: 2;
    font-weight: 600;
    color: #f89b32;
  }

  .logo-vesionn-about {
    background-repeat: initial;
    background-position: center;
  }

  .wi75 {
    width: 75%;
    font-size: 13px;
  }

  .text-list {
    font-size: 13px;
    display: flex;
    line-height: 2;
  }
  .subAbout-scaned-sec {
    display: block;
    width: 100%;
  }
  .iamger {
    width: 100%;
  }
  .About-scaned-sec {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 992px) {
  .sett {
    background-position: center;
  }
}

@media only screen and (max-width: 768px) {
  .sett {
    padding: 2rem 2rem 2rem 2rem;
    height: auto;
  }
  .wi75 {
    font-size: 1.4vw; /* Adjust as needed */
  }
  .mass-sub-text {
    font-size: 1.7vw; /* Adjust as needed */
  }
  .main-sub-text {
    font-size: 22px;
    text-align: center;
    line-height: 2;
    font-weight: 600;
    color: #f89b32;
  }
}
@media only screen and (max-width: 576px) {
  
  #drawer {
    width: 95% !important;
  }

  .wi75 {
    /* margin-top: -104px; */
    line-height: 3.4;
    font-size: 3vw; /* Adjust as needed */
  }

  .About-First-sec,
  .About-scaned-sec {
    flex-wrap: wrap;
  }
  .About-First-sec {
    flex-wrap: wrap;
    height: auto;
    min-height: 24rem;
  }
  .About-New-wrapper {
    flex-wrap: wrap;
  }
  .ourmassage {
    padding: 26px 20px 33px 20px;
  }

  .line-orange {
    height: 2px;
    background-color: orange;
    width: 14rem;
     margin-top: 1rem;
    margin-left: 35%;
    margin-right: 35%;
  }
  .rateus-wraper {
    width: 100%;
    display: flex;
    min-height: 378px;
    justify-content: center;
    padding: 47px 21px 31px 8px;
    flex-direction: column;
  }
  .text-list {
    font-size: 15px;
    display: flex;
    line-height: 2;
  }

  .sett {
    width: 100%;
  }
  .rateus-list {
    display: flex;
    flex-wrap: wrap;
  }
  .About-last-sec {
    display: flex;
    width: 100%;
    flex-wrap: wrap-reverse;
  }

  .mass-sub-text {
    font-size: 4vw; /* Adjust as needed */
  }
  .iamger {
    width: 100%;
  }
  .logo-vesionn-about {
    height: 231px;
    width: 100%;
  }




  .wi25 {
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .wi75 {
    font-family: "ArbFONTS-Almarai-Light", sans-serif;
    width: 100%;
    align-items: center;
    text-align: justify;
    line-height: 2.9;
    display: flex;
  }
  .main-sub-text {
    font-size: 29px;
    text-align: center;
    line-height: 2;
    font-weight: 600;
    color: #f89b32;
  }

  .subAbout-scaned-sec {
    display: block;
    width: 100%;
  }

  .sett {
    background-color: #ffffff;
    height: 12.5rem;
  }




}

.text-bold {
  font-weight: 900;
}


