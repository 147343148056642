@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Almarai:400,700|Tajawal:500");
.OurServices-wrapper {
  font-family: "Almarai", sans-serif;
  background-image: url("./../../assets/images/background_img/construction-silhouette-1.png");
  background-size: cover;
  background-color: #1c1c1c;
}
:root {
  --black: #000000;
  --white: #ffffff;
  --neon-carrot: #f89b32;
  --font-size-xxxl: 20.6px;
  --font-size-m: 22px;
  --font-size-xxxl2: 32px;
  --font-size-xl: 35px;
  --font-family-almarai: "Almarai", Helvetica;
  --font-family-tajawal: "Tajawal", Helvetica;
}
* {
  box-sizing: border-box;
}

.relative-position {
  position: relative;  margin: -1px 5px 5px 5px;
}
.img-container {
  width: 88rem;
  max-width: 100%;
  margin: 0 auto;
}

.flex-center {
  display: flex;
  width: 100%;
  justify-content: center;
}

.flex-centerjustify {
  display: flex;
  /* width: 100%; */
  justify-content: center;
}


.primary-color {
  color: #f90;
}

body.scanning .loader-wrapper {
  display: flex;
}

body.scanning h1 {
  display: none;
}

.main-text {
  margin-bottom: 30px;
  font-size: 30px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.navbar-link-text.almarai-normal-white-20-6px {
  position: relative;
}
.navbar-link-text.almarai-normal-white-20-6px:hover {
  color: #f90;
}

.navbar-link-text.almarai-normal-white-20-6px::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 100%;
  border-radius: 27px;
  height: 4px;
  background-color: transparent;
  transition: background-color 0.8s ease;
}

.navbar-link-text.almarai-normal-white-20-6px:hover::after {
  background-color: rgb(255, 255, 255);
}

.navbar-link-text.almarai-normal-white-20-6px.active::after {
  background-color: #f90;
  color: #f90;
}

.navbar-link-text.almarai-normal-white-20-6px.active-title::after {
  background-color: rgb(255, 255, 255);
  border-radius: 0px;
  font-size: 29px;
}

.re-design-ebdaa-sa-2 {
  align-items: flex-start;
  display: flex;
  width: 100%;
}

.text-1 {
  font-size: var(--font-size-m);
  font-weight: 400;
  line-height: normal;
  min-height: 25px;
  min-width: 425px;
}

.re-design-ebdaa-sa-2 .image-container-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.image-3 {
  height: 469px;
  margin-left: 0.18px;
  margin-top: 15px;
  width: 468px;
}

.image-1 {
  height: 469px;
  margin-left: 0.18px;
  width: 468px;
}

.image-2 {
  height: 469px;
  margin-top: 14px;
  width: 468px;
}

.image-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 242.64px;
  margin-left: 15px;
  min-height: 953px;
  width: 469px;
}

.image {
  height: 469px;
  width: 469px;
}

.image-container-1 {
  gap: 21px;
  margin-bottom: 248.64px;
  min-height: 959px;
  width: 469px;
}

.title {
  line-height: 1.5;
  text-align: right;
}

.title:not(:dir(rtl)) {
  text-align: left;
  text-transform: capitalize;
}

.title-text {
  margin-top: 10px;
}

.title-description {
  margin-top: 10px;
}

.fontsize16 {
  font-size: 16px;
}
